import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import Input from "./Input";
import { createComment } from "../services/api";
import * as storage from '../storage.js';

// import audioSentMp3 from "../assets/iphone_msg_sent.mp3";
// const audioSent = new Audio(audioSentMp3);

const CommentsInput = ({ postId }) => {
  const [text, setText] = useState("");
  const [uploading, setUploading] = useState(false);

  //
  const onSubmit = () => {
    // get locations
    const { longitude, latitude } = JSON.parse(
      storage.get("location")
    );

    setUploading(true);
    createComment(postId, {
      comment: { text, longitude, latitude },
    })
      .then(() => {
        setText("");
        setUploading(false);
        // audioSent.play();
      })
      .catch((err) => {
        setUploading(false);
      });
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Input
        style={
          uploading && {
            opacity: 0.5,
            pointerEvents: "none",
          }
        }
        onTextChange={setText}
        onSubmit={onSubmit}
        text={text}
        label={"Comment here ..."}
        buttonText={"Create a comment"}
        title={"Create a Comment"}
        minCharacters={2}
      />
      {uploading && <LinearProgress sx={{ margin: "10px 0 20px" }} />}
    </div>
  );
};

export default CommentsInput;
