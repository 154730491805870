import axios from "axios";
import * as storage from "../storage.js";

const timeout = 5000;
const baseURL = "https://qaos.app/api";
// const baseURL = "http://localhost:3000/api";
// const baseURL = "http://192.168.1.197:3000/api";

const api = axios.create({ baseURL });
const headers = { "Content-Type": "application/json" };
let authInProgress = false;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function auth(fn) {
  const sync = async () => {

    // If auth is in progress, 
    // wait for first auth to finish
    if (authInProgress) {
      while (authInProgress) await sleep(100);
      return;
    }

    // If auth is not in progress,
    authInProgress = true;

    try {
      // Connect to get token
      const { data } = await connect(storage.get("token"));
      
      storage.set('token', data.token);
      storage.set('user_id', data.user.id);
      headers.Authorization = "Bearer " + data.token;
      authInProgress = false;

      return data;
    } catch (error) {
      authInProgress = false;
      console.error(error);
    }
  };

  return async function (...args) {
    if (!headers.Authorization) {
      await sync();
    }

    try {
      return fn(...args);
    } catch (error) {
      if (error.response.status === 401) {
        delete headers.Authorization;
        storage.remove("token");
        await sync();
        return fn(...args);
      }
      throw error;
    }
  };
}

export const connect = async function (token, body) {
  const { data } = await api.post(`/connect`, body, {
    timeout,
    headers: { ...headers, ...{ Authorization: (token && `Bearer ${token}`) || undefined } },
  });
  return data;
};

export const getStatus = auth(async function () {
  const { data } = await api.get(`/status`, { timeout, headers });
  return data;
});

export const updateUserInfo = auth(async function (info) {
  await api.put("/users", info, { timeout, headers });
  return;
});

export const getLocationByIp = auth(async function (ip) {
  const { data } = await api.get(`/geolocation?ip=${ip}`, { timeout, headers });
  return data;
});

export const getPost = auth(async function (postId) {
  const { data } = await api.get(`/posts/${postId}`, { timeout, headers });
  return data;
});

export const getPosts = auth(async function (locationId, seq) {
  const params = { id: locationId };
  if (seq) params.seq = seq;

  const { data } = await api.get("/posts", { params, timeout, headers });
  return data;
});

export const createPost = auth(async function (post) {
  const { data } = await api.post("/posts", post, { timeout, headers });
  return data;
});

export const getComments = auth(async function (postId, seq) {
  const params = seq ? { seq } : {};
  const { data } = await api.get(`/posts/${postId}/comments`, {
    params,
    timeout,
    headers,
  });
  return data;
});

export const createComment = auth(async function (postId, comments) {
  const { data } = await api.post(`/posts/${postId}/comments`, comments, {
    timeout,
    headers,
  });
  return data;
});

export const getSubscription = auth(async function (type, item) {
  const {data} = await api.get(`/subscriptions/${type}/${item}`, { timeout, headers });
  return data;
});

export const subscribe = auth(async function (type, item) {
  await api.post(
    `/subscriptions/${type}/${item}`,
    {},
    { timeout, headers }
  );
  return {};
});

export const unsubscribe = auth(async function (type, item) {
  await api.delete(`/subscriptions/${type}/${item}`, { timeout, headers });
  return {};
});

/*
 * Server-Sent Events listener */
function sseEventListener(url, eventHandler) {
  if (!window.EventSource) {
    console.error("This browser doesn't support Server-Sent Events.");
    return;
  }

  const source = new EventSource(url);

  source.onmessage = function (event) {
    eventHandler(JSON.parse(event.data));
  };

  source.onerror = function (error) {
    console.error("Error occurred in Server-Sent Events connection:", error);
  };
}

export const listenForPosts = auth(async function (locationId, cb) {
  sseEventListener(
    `${baseURL}/posts/stream?id=${locationId}&token=${
      headers.Authorization.split(" ")[1]
    }`,
    cb
  );
});

export const listenForComments = auth(async function (postId, cb) {
  sseEventListener(
    `${baseURL}/posts/${postId}/comments/stream?token=${
      headers.Authorization.split(" ")[1]
    }`,
    cb
  );
});
