
import { subscribe } from "../services/api";
import * as storage from "../storage.js";
import { store } from "../states/store.js";
import { setStreamSubscriptionStatus } from "../states/slices/stream-subscription.js";

export async function setup() {
    const mainLocationId = storage.get("main_location_id");
    const subscriptionLocationId = storage.get("subscription_location_id");

    if (subscriptionLocationId  && mainLocationId !== subscriptionLocationId) {
        await subscribe('location', mainLocationId);
        storage.set("subscription_location_id", mainLocationId);
    }

    store.dispatch(setStreamSubscriptionStatus(!!subscriptionLocationId));
}
