
export default class IDGenerator {
  constructor() {
    this.maxPoints = 999999999999999;
    this.pointScale = {
      // Q: 1000000000000000,
      T: 1000000000000,
      B: 1000000000,
      M: 1000000,
      K: 1000,
      // Z: 0,
    };
  }

  generateId(country, lat, lng, pointsCount) {
    const latParts = this.formatCoordinateParts(lat);
    const lngParts = this.formatCoordinateParts(lng);
    const points = this.formatPoints(pointsCount);

    return `${country}` + 
              `${latParts.sign}${latParts.whole}` +
              `${lngParts.sign}${lngParts.whole}` + 
              `${latParts.frac}` +
              `${lngParts.frac}` + 
            `${points.scale}${points.count}`;
  }

  formatCoordinateParts(coord) {
    const sign = coord < 0 ? 'M' : 'P';
    const absCoord = Math.abs(coord);
    const whole = String(Math.floor(absCoord)).padStart(3, '0');
    const frac = String(absCoord % 1).substring(2).padEnd(18, '0').slice(0, 18);
    
    return {sign, whole, frac};
  }

  formatPoints(pointsCount) {
    const scale = Object.keys(this.pointScale).find(x => pointsCount >= this.pointScale[x]);
    if (!scale || pointsCount > this.maxPoints) throw new Error('Invalid points count');
    const count = String(Math.floor(pointsCount/this.pointScale[scale])).padStart(3, '0');

    return {count, scale};
  }

  validateId(id) {
    const regex = /^([A-Z]{2})([PM]\d{3}){2}(\d{18}){2}([BTMK]\d{3})$/;
    return regex.test(id);
  }

  parseId(id) {
    // AM(2) P040(6) M164(10) 0400400000000005(26) 0000000000099995(42) B010(46)
    if (!this.validateId(id)) throw new Error('Invalid Id');
    const country = id.substring(0, 2);
    const lat = this.parseCoordinate(id.substring(2, 3), id.substring(3, 6), id.substring(10, 28));
    const lng = this.parseCoordinate(id.substring(6, 7), id.substring(7, 10), id.substring(28, 46));
    const points = this.parsePoints(id.substring(46, 47), id.substring(47, 50));

    return { country, lat, lng, points };
  }

  parseCoordinate(coordsign, coordWhole, coordFrac) {
    const sign = coordsign === 'M' ? -1 : 1;
    return sign * parseFloat(coordWhole + '.' + coordFrac);
  }

  parsePoints(scale, number) {
    if (!this.pointScale[scale]) throw new Error('Invalid points scale');
    return parseInt(number) * this.pointScale[scale];
  }
}

