import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CardActions } from "@mui/material";
import { relativeTime, distanceOf } from "../utils";

const Comment = ({ comment }) => {
  const { userId, text, createdAt } = comment;
  const [isNew, setIsNew] = useState(false);
  const [time, setTime] = useState("");

  useEffect(() => {
    const interval = 5000;
    const createdAtDate = new Date(createdAt);
    const timeDifference = Date.now() - createdAtDate.getTime();

    setTime(relativeTime(createdAt));
    if (timeDifference < interval) setIsNew(true);

    const timer = setInterval(() => {
      setIsNew(false);
      setTime(relativeTime(createdAt));
    }, interval);

    return () => clearTimeout(timer);
  }, [createdAt]);

  return (
    <Card
      sx={{
        flexGrow: 1,
        margin: "5px",
        boxShadow: "none",
        border: "1px solid #f8f8f8",
        background: isNew ? "#FcFcFc" : "#f3f3f3",
        padding: "7px 20px",
      }}
    >
      <CardContent style={{ padding: "5px 0px" }}>
        <CardActions
          sx={{ justifyContent: "space-between", padding: "0 0 2px" }}
        >
          <Typography variant="caption" sx={{ color: "#666" }}>
            #{userId.slice(-8)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            ~ {distanceOf(comment.latitude, comment.longitude) || "-"} km away
          </Typography>
        </CardActions>

        <Typography
          variant="body2"
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          {text}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: "space-between",
          padding: 0,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {time}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default Comment;
