import React from "react";
import Scroller from "./Scroller";

import Comment from "./Comment";
import { getComments, listenForComments } from "../services/api";

// import audioStreamMp3 from "../assets/pop_tone.mp3";
// const audioStream = new Audio(audioStreamMp3);

const Comments = ({postId}) => {

  async function fetchItems(lastItem) {
    const seq = lastItem?.seq || null;
    return getComments(postId, seq);
  }

  function fetchStream(cb) {
    listenForComments(postId, cb);
  }

  return (
        <Scroller
          fetchStream={fetchStream}
          fetchItems={fetchItems}
          renderItem={(comment) => <Comment key={comment.id} comment={comment} />}
          emptyMessage={"No comments yet, be the first to comment!"}
          // audioStream={audioStream}
        />
  );
};

export default Comments;
