import IDGenerator from "../classes/IDGenerator";
import Filattice from "filattice";
import { getLocationByIp, getPosts } from "../services/api";
import countries from "../assets/countries.json";
import { distanceOf } from "../utils.js";
import * as storage from "../storage.js";

import { store } from "../states/store.js";

import { setLocationIdStatus } from "../states/slices/location-id.js";
import { setLocationPermission } from "../states/slices/location-permission.js";

function parseJson(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return null;
  }
}

const points = [100000000000, 1000000, 1000];
const lattices = points.map((pointsCount) => new Filattice(pointsCount));
const idGenerator = new IDGenerator();

async function getUserLocation() {
  if (!navigator.geolocation) {
    console.error("Geolocation is not supported by your browser");
    return null;
  }

  const onLocationError = () => Promise.resolve(null);
  const onLocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    storage.set("location", JSON.stringify({ latitude, longitude }));
    storage.set("location_updated_at", Date.now());
    return { latitude, longitude };
  };

  // ###### STATUS ######

  const setStatus = (state) => {
    const status = ["granted", "prompt"].includes(state) ? "granted" : "denied";
    store.dispatch(setLocationPermission(status));
    storage.set("location_status", status);
    return status;
  };
  const permission = await navigator.permissions.query({ name: "geolocation" });
  const status = setStatus(permission.state);

  // Watch for permission changes
  permission.onchange = function () {
    const status = setStatus(this.state);

    // Request Current Location
    if (status === "granted") {
      try {
        navigator.geolocation.getCurrentPosition(
          (pos) => onLocationSuccess(pos),
          (err) => onLocationError()
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  // ###### LOCATION ######

  const storedStatus = storage.get("location_status");
  const storedLocationUpdatedAt = storage.get("location_updated_at");
  const storedLocation = parseJson(storage.get("location"));

  if (
    storedStatus === "granted" &&
    storedLocation &&
    storedLocationUpdatedAt &&
    Date.now() - storedLocationUpdatedAt < 10 * 60 * 1000
  ) {
    return storedLocation;
  }

  // Request Current Location
  if (status === "granted") {
    return new Promise((res, rej) =>
      navigator.geolocation.getCurrentPosition(
        (pos) => res(onLocationSuccess(pos)),
        (err) => rej(onLocationError())
      )
    );
  }

  return null;
}

async function getRegionLocation(country, ip) {
  const storedLocation = parseJson(storage.get("location"));
  const storedUpdatedAt = storage.get("location_by_region_updated_at");

  if (
    storedLocation &&
    storedUpdatedAt &&
    Date.now() - storedUpdatedAt < 10 * 60 * 1000
  ) {
    return storedLocation;
  }

  // get location by ip
  if (ip && /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(ip)) {
    try {
      const { data } = await getLocationByIp(ip);

      storage.set("location", JSON.stringify(data));
      storage.set("location_by_region_updated_at", Date.now());
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  // get location by static data
  const location = countries[country];
  storage.set("location", JSON.stringify(location));
  storage.set("location_by_region_updated_at", Date.now());
  return location;
}

function getLocationIds(country, { latitude, longitude }) {
  const storedLocationIds = parseJson(storage.get("location_ids"));
  const distance = distanceOf(latitude, longitude);
  
  if (storedLocationIds && distance && distance < 1) {
    return {
      locationIds: storedLocationIds,
      locationChanged: false,
    }
  }

  const locationIds = lattices.map((lattice, i) =>
    lattice
      .nearbyPoints([latitude, longitude])
      .map(([lat, lon]) => idGenerator.generateId(country, lat, lon, points[i]))
  );

  storage.set("location_ids", JSON.stringify(locationIds));

  return {
    locationIds,
    locationChanged: storedLocationIds && storedLocationIds.toString() === locationIds.toString() ? false : true,
  }

}

async function getMainLocationId(locationIds, forceUpdate = false) {
  const storedMainLocationId = storage.get("main_location_id");
  const storedMainLocationIdUpdatedAt = storage.get(
    "main_location_id_updated_at"
  );

  if (
    !forceUpdate &&
    storedMainLocationId &&
    storedMainLocationIdUpdatedAt &&
    Date.now() - storedMainLocationIdUpdatedAt < 30 * 60 * 1000
  ) {
    return storedMainLocationId;
  }

  const counts = await Promise.all(
    locationIds.map(async ([id]) => {
      const posts = await getPosts(id);
      return { id, count: posts.length };
    })
  );

  let mainLocationId = null;
  for (let { id, count } of counts) {
    mainLocationId = id;
    if (count > 10) break;
  }

  storage.set("main_location_id", mainLocationId);
  storage.set("main_location_id_updated_at", Date.now());

  return mainLocationId;
}

export async function setup() {
  store.dispatch(setLocationIdStatus("loading"));

  try {
    const storedCountry = storage.get("country");
    const storedIp = storage.get("ip");

    let userLocation = null;
    try {
      userLocation = await getUserLocation();
    } catch (error) {
      console.log(error);
    }

    const regionLocation =
      !userLocation && (await getRegionLocation(storedCountry, storedIp));

    const location = userLocation || regionLocation;
    const {locationIds, locationChanged } = getLocationIds(storedCountry, location);
    await getMainLocationId(locationIds, locationChanged);

    store.dispatch(setLocationIdStatus("success"));
  } catch (error) {
    store.dispatch(setLocationIdStatus("error"));
    console.log(error);
  }
}
