import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { subscribe, unsubscribe, getSubscription } from "../services/api";
import Subscription from "./Subscription.js";

function CommentsSubscription({ postId }) {
  const notification = useSelector((state) => state.notificationPermission);
  const [status, setStatus] = useState(null);

  const onChange = (event) => {
    const newStatus = event.target.checked;
    const fn = newStatus ? subscribe : unsubscribe;

    fn("post", postId)
      .then((status) => {
        setStatus(newStatus);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getSubscription("post", postId)
      .then(({ data }) => {
        setStatus(!!data);
      })
      .catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ position: "absolute", right: 0, bottom: 0 }}>
      {notification.status === "granted" && (
        <Subscription onChange={onChange} status={status} />
      )}
    </div>
  );
}

export default CommentsSubscription;
