import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Alert,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  IconButton,
  Popover,
} from "@mui/material";
import LocationOffIcon from "@mui/icons-material/LocationOffOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOffOutlined";
import NotificationsOnIcon from "@mui/icons-material/NotificationsActiveOutlined";

import logo from "../assets/logo.png";

const successColor = "#157ebc";
const errorColor = "#9e9e9e";

const Header = () => {
  const locationPermission = useSelector((state) => state.locationPermission);

  const notificationPermission = useSelector(
    (state) => state.notificationPermission
  );

  const [anchorLocationEl, setAnchorLocationEl] = useState(null);
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);

  const locationIsReady = ["granted", "denied"].includes(
    locationPermission.status
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: "white" }}>
        <Toolbar style={{ justifyContent: "center", padding: 0 }}>
          <Box
            sx={{
              padding: 0,
              maxWidth: "900px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Toolbar sx={{ padding: "0 !important" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Link href="/" style={{ textDecoration: "none" }}>
                  <Toolbar style={{ width: "90px" }}>
                    <Box>
                      <img
                        src={logo}
                        alt="Logo"
                        style={{
                          marginTop: 4,
                          marginRight: 5,
                          height: 38,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        textShadow: "#0CB 1px 0px",
                        flexGrow: 1,
                        fontSize: "1.1rem",
                        color: "#62319d",
                      }}
                    >
                      qaos
                    </Typography>
                  </Toolbar>
                </Link>
              </Box>
              <Box sx={{ paddingRight: "10px" }}>
                {locationIsReady && (
                  <IconButton
                    aria-describedby={"location-area"}
                    color="secondary"
                    aria-label="delete"
                    onClick={(event) => {
                      setAnchorLocationEl(event.currentTarget);
                    }}
                  >
                    {locationPermission.status === "granted" ? (
                      <LocationOnIcon sx={{ color: successColor }} />
                    ) : (
                      <LocationOffIcon sx={{ color: errorColor }} />
                    )}
                  </IconButton>
                )}

                {notificationPermission.status && (
                  <IconButton
                    aria-describedby={"location-area"}
                    color="secondary"
                    aria-label="delete"
                    onClick={(event) => {
                      setAnchorNotificationEl(event.currentTarget);
                    }}
                  >
                    {notificationPermission.status === "granted" ? (
                      <NotificationsOnIcon sx={{ color: successColor }} />
                    ) : (
                      <NotificationsOffIcon sx={{ color: errorColor }} />
                    )}
                  </IconButton>
                )}

                <Popover
                  id={"location-area"}
                  anchorEl={anchorLocationEl}
                  open={Boolean(anchorLocationEl)}
                  onClose={() => setAnchorLocationEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                    {locationPermission.status === "granted" ? (
                      <Alert severity="success">
                        Location permission granted
                      </Alert>
                    ) : (
                      <PermissionGuideComponent
                        title="Location permission denied"
                        guide={getLocationPermissionGuide}
                      />
                    )}
                </Popover>

                <Popover
                  id={"location-area"}
                  anchorEl={anchorNotificationEl}
                  open={Boolean(anchorNotificationEl)}
                  onClose={() => setAnchorNotificationEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                    {notificationPermission.status === "granted" ? (
                      <Typography sx={{ color: "green" }}>
                        <Alert severity="success">
                          Notification permission granted
                        </Alert>
                      </Typography>
                    ) : (
                      <PermissionGuideComponent
                        title="Notification permission denied"
                        guide={getNotificationPermissionGuide}
                      />
                    )}
                </Popover>
              </Box>
            </Toolbar>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

/**
 *
 */
function PermissionGuideComponent({ title, guide }) {
  return (
    <div>
      <Alert severity="error">{title}</Alert>

      <ol
        style={{
          margin: 0,
          paddingLeft: 0,
          padding: 10,
          listStyle: "inside decimal",
          color: "#515151",
          fontSize: 14,
        }}
      >
        {guide().map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ol>
    </div>
  );
}
function getNotificationPermissionGuide() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("windows") || userAgent.includes("linux")) {
    return [
      "Look for the lock icon or your browser's site settings in the URL bar.",
      "Find Notifications under permissions and change the setting to 'Allow'.",
      "Refresh the page to apply changes.",
    ];
  }

  if (userAgent.includes("macintosh")) {
    return [
      "Look for the lock icon or your browser's site settings in the URL bar.",
      "Find Notifications under permissions and change the setting to 'Allow'.",
      "Refresh the page to apply changes.",
    ];
  }

  if (userAgent.includes("android")) {
    return [
      "Open your browser's settings and find the site settings or permissions section.",
      "Find Notifications and change the setting to 'Allow'.",
    ];
  }

  if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
    return [
      "Open Settings and scroll down to your browser.",
      "Tap on Notifications and turn on Allow Notifications.",
    ];
  }

  // If we don't have specific instructions for this platform, show a generic guide
  return [
    "Open your browser's settings or preferences and find the site settings or permissions section.",
    "Find Notifications and change the setting to 'Allow'.",
    "Refresh the page to apply changes.",
  ];
}

function getLocationPermissionGuide() {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("windows") || userAgent.includes("linux")) {
    return [
      "Look for the lock icon or your browser's site settings in the URL bar.",
      "Find Location under permissions and change the setting to 'Allow'.",
      "Refresh the page to apply changes.",
    ];
  }

  if (userAgent.includes("macintosh")) {
    return [
      "Look for the lock icon or your browser's site settings in the URL bar.",
      "Find Location under permissions and change the setting to 'Allow'.",
      "Refresh the page to apply changes.",
    ];
  }

  if (userAgent.includes("android")) {
    return [
      "Open your browser's settings and find the site settings or permissions section.",
      "Find Location and change the setting to 'Allow'.",
    ];
  }

  if (userAgent.includes("iphone") || userAgent.includes("ipad")) {
    return [
      "Open Settings and scroll down to your browser.",
      "Tap on Location and turn on Allow Location Access.",
    ];
  }

  // If we don't have specific instructions for this platform, show a generic guide
  return [
    "Open your browser's settings or preferences and find the site settings or permissions section.",
    "Find Location and change the setting to 'Allow'.",
    "Refresh the page to apply changes.",
  ];
}
