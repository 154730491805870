import { getStatus, connect } from "../services/api";
import * as storage from "../storage.js";

async function updateCountryAndIp() {
  const storedRegionUpdatedAt = storage.get("country_updated_at");
  const storedCountry = storage.get("country");
  const storedIp = storage.get("ip");

  if (
    storedRegionUpdatedAt &&
    storedCountry &&
    storedIp &&
    Date.now() - storedRegionUpdatedAt < 5 * 60 * 1000
  ) {
    return;
  }

  const {
    data: { country, ip },
  } = await getStatus();

  storage.set("country", country);
  storage.set("ip", ip);
  storage.set("country_updated_at", Date.now());
}

async function autorize() {
  const storedToken = storage.get("token");
  const storedTokenUpdatedAt = storage.get("token_updated_at");

  if (
    storedToken &&
    storedTokenUpdatedAt &&
    Date.now() - storedTokenUpdatedAt < 10 * 60 * 1000
  ) {
    return;
  }

  const { data } = await connect(storedToken);

  storage.set("token", data.token);
  storage.set("user_id", data.user.id);
  storage.set("token_updated_at", Date.now());
}

export async function setup() {
  await updateCountryAndIp();
  await autorize();
}
