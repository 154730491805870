
import { configureStore } from '@reduxjs/toolkit';
import locationIdReducer from "./slices/location-id.js";
import locationPermissionReducer from "./slices/location-permission.js";
import notificationPermissionReducer from "./slices/notification-permission.js";
import streamSubscriptionReducer from "./slices/stream-subscription.js";

export const store = configureStore({
    reducer: {
      locationId: locationIdReducer,
      locationPermission: locationPermissionReducer,
      notificationPermission: notificationPermissionReducer,
      streamSubscription: streamSubscriptionReducer,
    }
  });