import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Location Id",
  initialState: {
    status: null,
  },
  reducers: {
    setLocationIdStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setLocationIdStatus } = slice.actions;
export default slice.reducer;
