import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Stream Subscription",
  initialState: {
    status: null,
  },
  reducers: {
    setStreamSubscriptionStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setStreamSubscriptionStatus } = slice.actions;
export default slice.reducer;
