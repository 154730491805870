import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Alert } from "@mui/material";

const Scroller = ({
  fetchStream,
  fetchItems,
  renderItem,
  emptyMessage,
  audioStream,
}) => {
  const [items, setItems] = useState([]);
  const [storedNewItems, setStoredNewItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [status, setStatus] = useState("");

  const observer = useRef();

  const loadMoreItems = useCallback(
    (firstTime) => {
      if (!hasMore) return;
      const lastItem = items[items.length - 1];

      setStatus("loading");
      fetchItems(lastItem)
        .then(({ data }) => {
          if (data.length === 0) {
            setStatus("loaded");
            return setHasMore(false);
          }
          setItems((prevItems) =>
            firstTime ? [...data] : [...prevItems, ...data]
          );

          setStatus("loaded");
        })
        .catch((err) => {
          setStatus("failed");
          console.log(err);
        });
    },
    [items, fetchItems, hasMore]
  );

  const lastItemRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadMoreItems]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop === 0 && storedNewItems.length > 0) {
      setItems((prevItems) => [...storedNewItems, ...prevItems]);
      setStoredNewItems([]);
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (fetchStream) {
      fetchStream((newItem) => {
        // const scrollTop =
        //   window.pageYOffset || document.documentElement.scrollTop;
        // if (!scrollTop || scrollTop < 10) {
        setItems((prevItems) => [newItem, ...storedNewItems, ...prevItems]);
        // } else {
        //   setStoredNewItems((prevStoredNewItems) => [
        //     newItem,
        //     ...prevStoredNewItems,
        //   ]);
        // }
        audioStream && audioStream.play();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStream]);

  useEffect(() => {
    loadMoreItems(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchItems]);

  return (
    <div>
      {status === "loaded" && items.length === 0 && (
        <Alert style={{ marginTop: "15px" }} severity="info">
          {emptyMessage || "No items"}
        </Alert>
      )}

      {status === "failed" && (
        <Alert style={{ marginTop: "15px" }} severity="error">
          Error occurred: Unable to retrieve data. Please refresh the page.
        </Alert>
      )}

      {items.map((item, index) => (
        <div key={index}>
          {renderItem(item)}
          {items.length === index + 1 && <div ref={lastItemRef} />}
        </div>
      ))}

      {status === "loading" && (
        <div style={{ textAlign: "center", padding: "10px" }}>
          <CircularProgress
            sx={{ alignContent: "center", color: "#ccc" }}
            size={14}
          />
        </div>
      )}
    </div>
  );
};

Scroller.propTypes = {
  fetchStream: PropTypes.func,
  fetchItems: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default Scroller;
