import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import Input from "./Input";
import { createPost, subscribe } from "../services/api";
import * as storage from "../storage.js";
import { store } from "../states/store.js";

// import audioSentMp3 from "../assets/iphone_msg_sent.mp3";
// const audioSent = new Audio(audioSentMp3);

const PostsInput = () => {
  const [text, setText] = useState("");
  const [uploading, setUploading] = useState(false);

  //
  const onSubmit = () => {
    // get locations
    const { longitude, latitude } = JSON.parse(storage.get("location"));
    const locationIds = JSON.parse(storage.get("location_ids"));
    const country = storage.get("country");

    setUploading(true);
    createPost({
      post: { country, text, longitude, latitude },
      locations: locationIds.flat(),
    })
      .then(({ data: { id } }) => {
        setText("");
        setUploading(false);

        if (store.getState().notificationPermission.status === "granted") {
          subscribe("post", id).catch(console.error);
        }
        // audioSent.play();
      })
      .catch((err) => {
        setUploading(false);
      });
  };

  return (
    <div>
      <Input
        style={
          uploading && {
            opacity: 0.5,
            pointerEvents: "none",
          }
        }
        onTextChange={setText}
        onSubmit={onSubmit}
        text={text}
        label={"Post here ..."}
        buttonText={"Create a Post"}
        title={"Create a Post"}
      />
      {uploading && <LinearProgress sx={{ margin: "10px 0 20px" }} />}
    </div>
  );
};

export default PostsInput;
