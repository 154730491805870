
const keyName = (value) => `_qaos_${value}_`;

export function set(key, value) {
    return localStorage.setItem(keyName(key), value);
}

export function get(key) {
    return localStorage.getItem(keyName(key));
}

export function remove(key) {
    return localStorage.removeItem(keyName(key));
}