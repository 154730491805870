import React from "react";
import Scroller from "./Scroller";

import Post from "./Post";
import { getPosts, listenForPosts } from "../services/api";
import * as storage from '../storage.js';

// import audioStreamMp3 from "../assets/small_sms_tone.mp3";
// const audioStream = new Audio(audioStreamMp3);

const Posts = () => {
  async function fetchItems(lastItem) {
    const locationId = storage.get("main_location_id");
    const seq = lastItem?.seq || null;
    return getPosts(locationId, seq);
  }

  function fetchStream(cb) {
    const locationId = storage.get("main_location_id");
    listenForPosts(locationId, cb);
  }

  return (
        <Scroller
          fetchStream={fetchStream}
          fetchItems={fetchItems}
          renderItem={(post) => <Post key={post.id} post={post} clickable={true} />}
          emptyMessage={"No posts yet, be the first to post in this location!"}
          // audioStream={audioStream}
        />
  );
};

export default Posts;
