import React, { useEffect } from "react";
import { Alert } from "@mui/material";
import Post from "../components/Post";
import { getPost } from "../services/api";

const Topic = ({postId}) => {
  const [postStatus, setPostStatus] = React.useState("loading");
  const [post, setPost] = React.useState(null);

  useEffect(() => {
    getPost(postId)
      .then(({ data }) => {
        setPostStatus("succeded");
        setPost(data);
      })
      .catch((err) => setPostStatus("failed"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      { postStatus === 'failed' && <Alert severity="error">Failed to load post</Alert> }
      { postStatus === 'loading' && <Alert severity="info">Loading post ...</Alert> }
      { postStatus === 'succeded' && <Post post={post} /> }
    </div>
  );
};

export default Topic;
