import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscribe, unsubscribe } from "../services/api";
import { setStreamSubscriptionStatus } from "../states/slices/stream-subscription.js";

import Subscription from "./Subscription.js";
import * as storage from "../storage.js";

function PostsSubscription() {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notificationPermission);
  const streamSubscription = useSelector((state) => state.streamSubscription);
  const [status, setStatus] = useState(streamSubscription.status);

  const onChange = (event) => {
    const newStatus = event.target.checked;
    setStatus(newStatus);

    const fn = newStatus ? subscribe : unsubscribe;
    const mainLocationId = storage.get("main_location_id");

    fn("location", mainLocationId)
      .then(() => {
        newStatus
          ? storage.set("subscription_location_id", mainLocationId)
          : storage.remove("subscription_location_id");

        dispatch(setStreamSubscriptionStatus(newStatus));
      })
      .catch(() => {
        setStatus(!newStatus);
      });
  };

  useEffect(() => {
    setStatus(streamSubscription.status);
  }, [streamSubscription]);

  return (
    <div style={{ position: "absolute", right: 0, bottom: 0 }}>
      {notification.status === "granted" && (
        <Subscription onChange={onChange} status={status} />
      )}
    </div>
  );
}

export default PostsSubscription;
