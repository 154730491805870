import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CardActions } from "@mui/material";
import { Link } from "react-router-dom";
import { relativeTime, distanceOf } from "../utils";
import CommentIcon from "../assets/comment.png";

const Post = ({ post, clickable }) => {
  const { id, userId, text, createdAt, commentsCount } = post;
  const [isNew, setIsNew] = useState(false);
  const [time, setTime] = useState("");

  useEffect(() => {
    const interval = 5000;
    const createdAtDate = new Date(createdAt);
    const timeDifference = Date.now() - createdAtDate.getTime();

    setTime(relativeTime(createdAt));
    if (timeDifference < interval) setIsNew(true);

    const timer = setInterval(() => {
      setIsNew(false);
      setTime(relativeTime(createdAt));
    }, interval);

    return () => clearTimeout(timer);
  }, [createdAt]);

  const truncateSize = 400;
  const truncate = clickable && text.length > truncateSize;

  const Content = () => (
    <div>
      <CardContent style={{ padding: "12px 16px 0px" }}>
        <CardActions
          sx={{ justifyContent: "space-between", padding: "0 0 5px" }}
        >
          <Typography variant="caption" sx={{ color: "#666" }}>
            #{userId.slice(-8)}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            ~ {distanceOf(post.latitude, post.longitude) || "-"} km away
          </Typography>
        </CardActions>
        <Typography
          component="div"
          variant="body1"
          sx={{
            flexGrow: 1,
            display: "flex",
          }}
        >
          {truncate ? text.slice(0, truncateSize) : text}
          {truncate && (
            <span
              style={{
                display: "contents",
                color: "#0087ff",
                textDecoration: "underline",
              }}
            >
              {" "}
              ... more ➝
            </span>
          )}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ justifyContent: "space-between", padding: "9px 16px 7px" }}
      >
        <Typography variant="caption" color="text.secondary">
          {time}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {commentsCount ? (
            <>
              <div style={{ display: "inline-block", marginRight: "4px" }}>
                {commentsCount}
              </div>
              <img
                src={CommentIcon}
                alt="comment"
                style={{
                  display: "inline-block",
                  marginTop: "3px",
                  float: "right",
                  width: "15px",
                }}
              />
            </>
          ) : (
            ""
          )}
        </Typography>
      </CardActions>
    </div>
  );

  return (
    <Card
      sx={{
        flexGrow: 1,
        margin: "5px",
        padding: "3px 1%",
        backgroundColor: isNew ? "#ffffee" : "white",
      }}
    >
      {clickable ? (
        <Link
          to={`/posts/${id}`}
          params={{ post }}
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "block",
          }}
        >
          <Content />
        </Link>
      ) : (
        <Content />
      )}
    </Card>
  );
};

export default Post;
