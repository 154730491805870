import React from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

import Comments from "../components/Comments";
import CommentsInput from "../components/CommentsInput";
import CommentsSubscription from "../components/CommentsSubscription";
import Topic from "../components/Topic";
import Layer from "../components/Layer";

const View = () => {
  const { id } = useParams();

  return (
    <Layer>
      <Container disableGutters maxWidth="md">
        <Topic postId={id} />
        <div style={{ position: "relative" }}>
          <CommentsInput postId={id} />
          <CommentsSubscription postId={id} />
        </div>
        <Comments postId={id} />
      </Container>
    </Layer>
  );
};

export default View;
