
import * as storage from './storage.js';

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

function calculateDistance([lat1, lon1], [lat2, lon2]) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);  // deg2rad below
  const dLon = deg2rad(lon2 - lon1); 
  const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
  ; 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const distance = R * c; // Distance in km
  return distance;
}

/*
* Distance */
export const distanceOf = (lat, lng) => {
  const location = storage.get("location");
  if (location) {
    try {
      const { latitude, longitude } = JSON.parse(location);
      return Math.ceil(calculateDistance([latitude, longitude], [lat, lng]));
    } catch (error) {
      console.log(error);
    }
  }
  return null;
}

export function relativeTime(time) {
  const currentTime = new Date();
  const timePassed = currentTime - new Date(time);

  const secondsPassed = Math.floor(timePassed / 1000);
  if (secondsPassed < 15) return 'jast now';
  if (secondsPassed < 60) return secondsPassed === 1 ? '1 second ago' : `${secondsPassed} seconds ago`;

  const minutesPassed = Math.floor(secondsPassed / 60);
  if (minutesPassed < 60) return minutesPassed === 1 ? '1 minute ago' : `${minutesPassed} minutes ago`;

  const hoursPassed = Math.floor(minutesPassed / 60);
  if (hoursPassed < 24) return hoursPassed === 1 ? '1 hour ago' : `${hoursPassed} hours ago`;

  const daysPassed = Math.floor(hoursPassed / 24);
  if (daysPassed < 30) return daysPassed === 1 ? '1 day ago' : `${daysPassed} days ago`;

  const monthsPassed = Math.floor(daysPassed / 30);
  if (monthsPassed < 12) return monthsPassed === 1 ? '1 month ago' : `${monthsPassed} months ago`;

  const yearsPassed = Math.floor(monthsPassed / 12);
  return yearsPassed === 1 ? '1 year ago' : `${yearsPassed} years ago`;
}
  