import React from "react";
import { Container, Alert } from "@mui/material";
import Layer from "../components/Layer";

const View = () => {
  return (
    <Layer>
      <Container disableGutters maxWidth="md">
        <Alert severity="error">Oops! Page not found.</Alert>
      </Container>
    </Layer>
  );
};

export default View;
