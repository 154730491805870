import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import * as authorization from "./setup/authorization.js";
import * as location from "./setup/location.js";
import * as notification from "./setup/notification.js";
import * as subscription from "./setup/subscription.js";

import Stream from "./screens/Stream";
import View from "./screens/View";
import NotFound from "./screens/NotFound";
import { store } from "./states/store.js";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

function App() {
  useEffect(() => {
    authorization
      .setup()
      .then(() => {
        location.setup().then(subscription.setup()).catch(console.error);
        notification.setup().catch(console.log);
      })
      .catch(console.log);
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Stream />} />
          <Route path="/posts/:id" element={<View />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
