import React from "react";
import { FormControlLabel, Switch } from "@mui/material";

function Subscription({ status, onChange, size }) {
  return (
    <div>
      {typeof status === "boolean" && (
        <FormControlLabel
          sx={{
            color: "#167196",
            fontFamily: "revert",
            fontSize: "12px",
          }}
          control={
            <Switch
              checked={status}
              size={size || "medium"}
              onChange={onChange}
              sx={{ marginLeft: "10px" }}
            />
          }
          label={
            <span
              style={{
                color: status ? "#167196" : "#999",
                fontFamily: "revert",
                fontSize: "14px",
              }}
            >
              Subscription
            </span>
          }
        />
      )}
    </div>
  );
}

export default Subscription;
