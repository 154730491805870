import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Location Permission",
  initialState: {
    status: null,
  },
  reducers: {
    setLocationPermission(state, action) {
      state.status = action.payload;
    },
  },
});

export const { setLocationPermission } = slice.actions;
export default slice.reducer;
