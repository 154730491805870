import React from "react";
import {
  TextField,
  Button,
  Box,
} from "@mui/material";

const TextBox = ({
  onTextChange,
  text,
  label,
  buttonText,
  onSubmit,
  minCharacters,
}) => {
  const handleChange = (e) => {
    if (onTextChange) {
      onTextChange(e.target.value);
    }
  };

  const min = minCharacters || 3;
  const handleButtonClick = () => {
    if (onSubmit && text.trim().length >= min) {
      onSubmit(text);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "98%", margin: '9px 1%',}}>
      <TextField
        label={label}
        multiline
        maxRows={4}
        value={text}
        onChange={handleChange}
        fullWidth
        sx={{backgroundColor: 'white'}}
        variant="outlined"
      />
      <Box mt={1}>
        <Button
          variant="contained"
          style={{ textTransform: "none" }}
          color="primary"
          onClick={handleButtonClick}
          disabled={text.trim().length < min}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default TextBox;

