import React from "react";
import { Box, Typography } from "@mui/material";

const BottomTemplate = () => (
  <Box sx={{ padding: "18px", margin: '30px', textAlign: 'center' }}>
    <Typography variant="caption" align="center" color="textSecondary">
      &copy; {new Date().getFullYear()} Qaos App <br/> Geolocation based anonymous chat app.
    </Typography>
  </Box>
);

export default BottomTemplate;
