import { updateUserInfo } from "../services/api";
import { store } from "../states/store.js";
import { setNotificationPermission } from "../states/slices/notification-permission.js";
import * as storage from "../storage.js";

const QAOS_VAPID_PUBLIC_KEY =
  "BHCj52Mr5QZyyZWL1EZiGleE63F7OM_LBB2rdYWJLQ0tl-v9fVfdjT5u0YlmWmE_mRZY8xDQSYVD9DsXCn-Pj34";

async function registerSubscription() {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js"
      );
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: QAOS_VAPID_PUBLIC_KEY,
      });

      storage.set("subscription", JSON.stringify(subscription));
      await updateUserInfo({ subscription });
    } catch (err) {
      console.error(err);
    }
  }
}

export async function setup() {
  const permission = await Notification.requestPermission();
  store.dispatch(setNotificationPermission(permission));
  if (permission === "granted") {
    registerSubscription();
  }

  // Follow to permissioin
  const permissionStatus = await navigator.permissions.query({
    name: "notifications",
  });
  permissionStatus.onchange = function (event) {
    store.dispatch(setNotificationPermission(this.state));
    if (event.target.state === "granted") {
      registerSubscription().catch(console.log);
    }
  };
}
