import React from "react";
import Header from "./Header";
import Bottom from "./Bottom";

function Layer({children}) {
  return (
    <>
      <div className="App">
        <div className="App-header">
          <Header />
        </div>
        <div
          className="App-body"
          style={{ padding: "13px 0", minHeight: "65vh" }}
        >
         {children}
        </div>
        <div className="App-footer">
          <Bottom />
        </div>
      </div>
    </>
  );
}

export default Layer;
