import React from "react";
import { Container } from "@mui/material";
import { Alert, Typography, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import Layer from "../components/Layer";
import Posts from "../components/Posts";
import PostsInput from "../components/PostsInput";
import PostsSubscription from "../components/PostsSubscription";

const PostsScreen = () => {
  const locationId = useSelector((state) => state.locationId);

  const ready =
    locationId.status === "success" || locationId.status === "error";

  return (
    <Layer>
      <Container disableGutters maxWidth="md">
        {locationId.status === "error" && (
          <Alert severity="error">
            Server error, please check your internet connection, or try again
            later
          </Alert>
        )}
        {!ready && (
          <div style={{ textAlign: "center", padding: "20% 10% 5%" }}>
            <CircularProgress
              size={30}
              sx={{
                animationDuration: "750ms",
                color: "#96b9d9",
              }}
            />
            <Typography variant="subtitle2" color="#999" sx={{ padding: "5%" }}>
              processing ...
            </Typography>
          </div>
        )}

        {locationId.status === "success" && (
          <div style={{ position: "relative" }}>
            <PostsInput />
            <PostsSubscription />
          </div>
        )}

        {locationId.status === "success" && <Posts />}
      </Container>
    </Layer>
  );
};

export default PostsScreen;
